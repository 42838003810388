var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-4 pt-10 md:pt-16"},[_c('div',{staticClass:"bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/3 mx-auto"},[_c('p',{staticClass:"font-bold text-2xl mb-6"},[_vm._v("Remplissez le formulaire")]),_c('div',[_c('ValidationObserver',{ref:"form",attrs:{"tag":"div"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"L'identifiant","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-input',{attrs:{"name":"identifiant","errors":errors[0],"label":"Identifiant"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}])})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":"L'email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-input',{attrs:{"readonly":_vm.emailreadonly,"name":"mail","errors":errors[0],"label":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}])})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Le nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-input',{attrs:{"name":"name","errors":errors[0],"label":"Nom"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}])})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Le(s) prénom(s)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-input',{attrs:{"name":"firstName","errors":errors[0],"label":"Prénoms"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}])})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Le mot de passe","rules":"required","vid":"user.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-input',{attrs:{"name":"password","type":"password","errors":errors[0],"autocomplete":"new-password","label":"Mot de passe"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}])})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"Le champs confirmer mot de passe","rules":"required|confirmed:user.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('m-input',{attrs:{"name":"confirm_password","type":"password","errors":errors[0],"autocomplete":"new-password","label":"Confirmer mot de passe"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}})]}}])})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('base-button',{attrs:{"full":"","text":"Annuler","color":"gray"}}),_c('base-button',{attrs:{"type":"submit","text":"Valider","loading":_vm.loading,"disabled":_vm.loading,"color":"primary","full":""}})],1)])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }