<template>
  <div class="p-4 pt-10 md:pt-16">
        <div class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/3 mx-auto">
          <p class="font-bold text-2xl mb-6">Remplissez le formulaire</p>
          <div>
            <ValidationObserver ref="form" tag="div"  class="">
                <form @submit.prevent="register">
                  <div class="mb-4">
                        <ValidationProvider name="L'identifiant" rules="required" v-slot="{ errors }">
                            <m-input name = "identifiant" :errors="errors[0]" v-model="user.username" label="Identifiant"/>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <ValidationProvider rules="required|email" name = "L'email" v-slot="{ errors }">
                            <m-input :readonly="emailreadonly" name="mail" :errors="errors[0]" v-model="user.email" label="Email"/>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <ValidationProvider name="Le nom" rules="required" v-slot="{ errors }">
                            <m-input name = "name" :errors="errors[0]" v-model="user.lastName" label="Nom"/>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <ValidationProvider name="Le(s) prénom(s)" rules="required" v-slot="{ errors }">
                            <m-input name = "firstName" :errors="errors[0]" v-model="user.firstName" label="Prénoms"/>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <ValidationProvider name="Le mot de passe" rules="required" v-slot="{ errors }" vid="user.password">
                            <m-input name ="password" type="password" :errors="errors[0]" v-model="user.password" autocomplete="new-password" label="Mot de passe"/>
                        </ValidationProvider>
                    </div>
                    <div class="mb-4">
                        <ValidationProvider name="Le champs confirmer mot de passe" rules="required|confirmed:user.password" v-slot="{ errors }">
                            <m-input name="confirm_password" type="password" :errors="errors[0]" v-model="user.password_confirmation" autocomplete="new-password" label="Confirmer mot de passe"/>
                        </ValidationProvider>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <base-button  full text="Annuler" color="gray" />
                        <base-button type="submit" text="Valider" :loading="loading" :disabled="loading" color="primary" full />
                    </div>
                </form>
            </ValidationObserver>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  data(){
    return {
      user: {
        username: '',
        email: '',
        lastName: '',
        firstName: '',
      },
      loading: false,
      emailreadonly: true
    }
  },

  methods: {
    register(){
      this.$refs.form.validateWithInfo().then(validate => {
        let { isValid, errors } = validate;
        console.log(isValid, errors)
        if (isValid){
          
          // const auth = this.$store.getters['auth/authUser']
          const reference = this.$route.params['reference']
          if (!reference) {
            return
          }
          const admin = this.user;
          // admin.roles = auth.roles.map(role => {
          //   return role.id || role._id
          // })
          this.loading = true
          this.$store.dispatch('admin/createAdminByInvitation', {reference, adminData: admin})
            .then((response)  => {
              console.log('response ---> ', response);
              this.$router.push({path: '/'})
            })
            .catch((error) => {
              console.log('error: ', error);

            })
            .finally(() => { this.loading = false})
        }
      });
    }
  },

  mounted () {
    if (this.$route.query['email']) {
      this.user.email = this.$route.query['email']
      this.emailreadonly = true;
    }
    console.log('route query --->', this.$route.query);
    // this.user.username = this.$route.query['identifiant'] ? this.$route.query['identifiant'] : ''
    
    // this.user.firstName = this.$route.query['firstname'] ? this.$route.query['firstname'] : ''

    // this.user.lastName = this.$route.query['lastname'] ? this.$route.query['lastname'] : ''
  }
}
</script>

<style>

</style>